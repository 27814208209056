<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
          <b-col
            cols="6"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.type') }}</label>
            <v-select
              v-model="type"
              :dir="$store.state.appConfig.layout.direction"
              :options="types"
              :reduce="item => item.id"
              label="name"
              class="w-100"
              @input='updateSelectedData("main")'

            />
          </b-col>
          <b-col v-if='type  === "championship" '
                 cols="6"
                 md='4'

          >
            <label>{{ $t('championship') }}</label>
            <v-select
              v-model="subType"
              :dir="$store.state.appConfig.layout.direction"
              :options="championships"
              :reduce="item => item.id"
              label="name_label"
              class="w-100"
              @input='updateSelectedData("sub")'

            />
          </b-col>
          <b-col v-if='type  === "league" '
                 cols="6"
                 md='4'

          >
            <label>{{ $t('league') }}</label>
            <v-select
              v-model="subType"
              :dir="$store.state.appConfig.layout.direction"
              :options="leagues"
              :reduce="item => item.id"
              @input='updateSelectedData("sub")'

              label='title_label'
              class="w-100"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.team') }}</label>
            <v-select
              v-model="searchFields.team_id"
              :dir="$store.state.appConfig.layout.direction"
              :options="teams"
              :reduce="item => item.id"
              label="name_label"
              class="w-100"
            />
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :has-export="'players'"
      :has-import="'players'"
      :has-import-multi="'remove-players'"
      :has-export-multi="'remove-players'"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'players',
      singularName: 'player',
      type:null,
      subType:null,
      championships:[],
      teams:[],
      leagues:[],
      searchFields: {
        name: null,
        status: null,
        team_id: null
      },
    }
  },
  watch: {

    type(val) {
      if (val === 'championship') {
        this.getChampionships(val)
      }
      if (val === 'league') {
        this.getLeagues(val)
      }
    },
    subType() {
      this.getTeams()


    },
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    getTeams() {

      this.axios.get(`/teamsByType?type=${this.type}&value=${this.subType}`)
        .then(res => {
          this.teams = res.data
        })
    },
    getChampionships() {
      this.axios.get('/lists/Championship')
        .then(res => {
          this.championships = res.data
        })
    },
    getLeagues() {
      this.axios.get('/lists/League')
        .then(res => {
          this.leagues = res.data
        })
    },
    updateSelectedData(type = null) {
      if(type === 'main'){
        this.subType = null
        this.searchFields.team_id = null
      }
      if(type === 'sub') {
        this.searchFields.team_id = null
      }




      },

  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 'active',
          name: this.$t('statuses.active'),
        },
        {
          id: 'inactive',
          name: this.$t('statuses.inactive'),
        },
      ]
    },
    types() {

      return[
        {
          id: 'championship',
          name: this.$t('championship'),
        },
        {
          id: 'league',
          name: this.$t('leagues'),
        },

      ]
    },


  },


}
</script>
